body {
  background-color: black !important;
  font-family: "Times New Roman", Times, serif;
}

.App {
  text-align: center;
}

.monthYrTbl {
  text-transform: uppercase;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.appHead {
  text-align: center;
  font-size: 15px;
  color: brown;
  text-shadow: 1px 1px 1px yellow;
  padding: 0.5rem 0rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* GameResultTable.css */
.game-result-table {
  /* height: 100vh !important;
  display: flex !important;
  flex-direction: column !important; */
}

.heading-container {
  /* background-color: #0cebeb !important;
  border: 2px solid black !important;
  border-radius: 5px !important;
  width: 100% !important;
  margin: auto !important;
  padding: 5px !important;
  background-color: #09fdfd69 !important;
  border-radius: 30px !important; */
  background-image: linear-gradient(to right, LightCyan, Aquamarine);
  text-align: center;
  border-color: red;
  color: black;
  margin-top: 3px;
  margin-bottom: 3px;
  font-weight: bold;
  font-size: 15px;
  border-radius: 30px;
}

.heading {
  color: black !important;
}

.heading-time {
  color: black !important;
  font-weight: bold !important;
}

.game-section {
  flex: 0 0 50% !important;
}

.game-card {
  background-color: LightCyan !important;
  font-weight: bold;
  padding: 0.5rem 0px;
  border-radius: 18px;
  border: 1px solid;
}

.game-name {
  color: blue !important;
  text-transform: uppercase !important;
  font-size: 20px !important;
  font-weight: bold !important;
  margin-bottom: 10px !important;
}

.game-time {
  color: black !important;
  font-size: 16px !important;
  font-weight: bold !important;
}

.previous-result {
  color: green !important;
  font-weight: bold !important;
  font-size: 30px !important;
}

.current-result {
  color: red !important;
  font-weight: bold !important;
  font-size: 20px !important;
  margin-right: 10px !important;
}

.correct-icon {
  color: green !important;
  font-size: 19px !important;
}

.previous-result {
  color: green !important;
  font-weight: bold !important;
  font-size: 16px !important;
  margin-right: 10px !important;
  /* Add margin here */
}

.fa-globe {
  margin-right: 10px !important;
  color: aqua;
  border: 1.5px solid black;
  border-radius: 30px;
  /* Add margin here */
}

.bottom-section {
  background-color: #f0f0f0 !important;
  padding: 20px 0;
}

.about-section {
  padding-right: 15px;
  /* Adjust as needed */
}

.about-section h3 {
  font-size: 18px;
  font-weight: bold;
}

.useful-links {
  font-size: 16px;
}

.bottom-link {
  margin-right: 10px;
}

.copyright-section {
  background-color: #33333385 !important;
  color: #fff;
  padding: 10px 0;
}

/* 
.usefull-links {
  align-items: flex-start;
} */

.footer-link {
  text-decoration: none !important;
  color: white;
}

.disclaimer h2,
.disclaimer a {
  color: red !important;
}

.disclaimer h3 {
  color: brown;
}

.disclaimer p {
  color: rgb(30, 30, 221);
  font-weight: bold;
}

.disclaimer span {
  color: red;
}

.navbar {
  background-color: #0cebeb;
  padding-bottom: 0 !important;
  border-radius: 30px;
  /* margin-bottom: 30px; */
  margin-top: 10px !important;
  color: red;
  /* font-weight: bold; */
}

.artical p {
  padding: 1rem;
}

.artical,
.artical p {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
  /* padding: 1rem; */
}

/* .navbar h3 {
  color: red;
  font-weight: bold;
  margin-bottom: 8px !important;
} */

.banner h2 {
  color: cyan;
  font-weight: bold;
}

.banner h3 {
  color: red;
  font-weight: bold;
}

.banner h4 {
  color: yellow;
  font-weight: bold;
  margin-bottom: 20px;
}

.banner button {
  font-size: 14px;
  background-color: rgb(129, 126, 126);
  border: transparent;
  color: white;
  font-weight: bold;
  border-radius: 10px;
}

/* ======= antd table ======= */

/* =======antd table======= */
.ant-table-thead tr th {
  text-wrap: nowrap;
  background-color: #17a2b8 !important;
  border-radius: 0px !important;
  /* padding: 0.5em !important; */
  /* margin: 1px !important;
  border: 1px solid #fff; */
  color: #fff !important;
  /* border-collapse: separate !important; */
  /* text-align: center !important; */
}

.ant-table-tbody td:nth-child(1) {
  text-wrap: nowrap;
  /* background-color: red !important; */
  border-radius: 0px !important;
  /* padding: 0px 3rem !important; */
  margin: 0px !important;
  border: 1px solid #fff;
  color: #fff !important;
  border-collapse: separate !important;
  text-align: center;
}

.ant-table-cell {
  border-collapse: separate !important;
  border: 1px solid #000 !important;
}

table {
  /* border-collapse: separate !important;
  border-spacing: 2px !important; */
}

.ant-table-wrapper .ant-table-cell {
  /* position: relative; */
  padding: 5px 1rem !important;
  background-color: #95999c;
  font-weight: bold;
  /* overflow-wrap: break-word; */
}

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
.ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  background-color: transparent !important;
}

.tableStyle {
  /* border: 1px solid; */
}

#go-button {
  color: #fff;
  background-color: #000;
  border-color: #930404;
  cursor: pointer;
  padding: 10px;
}

#go-button:hover {
  background-color: #282c34;
}

#month-select,
#year-select,
#game-select,
#go-button {
  width: 100%;
  /* padding: 10px; */
  /* font-size: 16px; */
  border-radius: 4px;
  /* background-color: #930404; */
  border: 1px solid;
  font-weight: bold !important;
}

/* ==========scrollbar======= */
::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #d3d3d3;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #000000a0;
  border: 1px solid;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #d3d3d3;
}

.toast-message {
  background: red !important;
  color: #fff !important;
  border: 5px solid #940505;
}

.Toastify__toast-body,
.toast-message button {
  color: #fff !important;
}

.Toastify__progress {
  background-color: green !important;
}

/* blink text */

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.blinking-text {
  animation: blink 1s infinite;
}

/* =====text color animation====== */
h2 {
  font-size: 16px;
  font-weight: bold;
  /* font-family: serif; */
  color: transparent;
  /* text-align: center; */
  animation: effect 1s linear infinite;
  text-transform: uppercase;
}

@keyframes effect {
  0% {
    background: linear-gradient(to left, #de2828, #410404);
    -webkit-background-clip: text;
  }

  100% {
    background: linear-gradient(to left, #ed1818, #df1f1f);
    -webkit-background-clip: text;
  }
}



/* advertisement section */
/* Main ad container with new background and color scheme */
.advertisementSection .ad-section {
  width: 92%;
  /* Covers 90%+ of screen width */
  margin: 20px auto;
  border: 3px solid #ff0077;
  /* Orange border */
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  background-color: #fef9e7;
  /* Light cream background */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

/* Heading styles */
.advertisementSection h3 {
  color: #00115f;
  /* Darker orange */
  font-size: 30px;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.advertisementSection h4 {
  color: #2c3e50;
  /* Dark gray text */
  font-size: 22px;
  margin: 10px 0;
}

/* Button styles */
.advertisementSection .ad-btn {
  /* display: inline-block; */
  /* padding: 10px; */
  /* margin: 5px; */
  /* font-size: 18px; */
  color: #fff;
  background-color: #3ca8e7;
  border: none;
  /* border-radius: 7px; */
  /* cursor: pointer; */
  /* transition: background-color 0.3s ease; */
  text-transform: uppercase;
  text-decoration: none;
}

/* Hover effect for buttons */
.advertisementSection .ad-btn:hover {
  background-color: #c0392b;
  color: #fff;
  /* Darker red on hover */
}

.fa-whatsapp {
  background: green;
  border-radius: 65%;
}

.fa-phone {
  color: green;
  /* border: solid 1px #fff; */
  box-shadow: 0px 0px 7px #fff;
  background: #fff;
  padding: 0.1rem;
  border-radius: 5px;
}